// src/App.tsx
import React, { useState }  from 'react';
import Chat from './components/Chat';
import Login from './components/Login';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Switching the dark mode on
    // You can also customize other theme properties like primary, secondary colors
  },
});

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <header className="App-header">
          {/* align center */}
          <img className='logo' alt='Ainia 2.0 Logo' src="/ainia-logo.svg"></img>
          <h3 style={{ textAlign: 'center' }}>Ainia, Your personal C2 Assistant</h3>
          <p style={{ textAlign: 'center' }}>Ask me anything you wanna know at C2.</p>
        </header>
        <main>
        {isLoggedIn && (
                <Chat/>
        )}
        <Login onLogin={() => setIsLoggedIn(true)} />
        <br />        
        </main>
      </div>
    </ThemeProvider>
  );
};

export default App;
