import React, { useState, useEffect } from 'react';
import { redirect } from 'react-router-dom'; // Import Redirect
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
    hd: string; // Define the 'hd' property as optional if it's not always present
    name: string;
    // Add other properties here if needed
}

interface LoginProps {
    onLogin: () => void; // Function to call when the user logs in successfully
}

const Login: React.FC<LoginProps> = ({ onLogin }) =>{
   // const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
    const [userData, setUserData] = useState<JwtPayload | null>(null);// State to store user data after login
    
    useEffect(() => {
        // Check if user data exists in local storage
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData) as JwtPayload;
            setUserData(parsedUserData);
            if (parsedUserData.hd === "c2comms.cx" || parsedUserData.hd === "c2native.io") {
                onLogin(); // Call onLogin if user data exists and meets conditions
            }
        }
    }, [onLogin]); // Run only on component mount

    // Function to handle successful login
    const handleLoginSuccess = (response: any) => {
        const decodedUserData = jwtDecode(response.credential) as JwtPayload;;
        console.log(decodedUserData);
        console.log(decodedUserData);
        if (decodedUserData.hd === "c2comms.cx" || decodedUserData.hd === "c2native.io") {
            onLogin(); 
            setUserData(decodedUserData); // Store user data
            localStorage.setItem('userData', JSON.stringify(decodedUserData)); // Store user data in local storage
        }else {
            alert("Sorry, you're not authorized to use the application.")
        }
    };

    // Function to handle logout
    const handleLogout = () => {
        //setIsLoggedIn(false); // Set login status to false
        setUserData(null); // Clear user data
        localStorage.removeItem('userData'); // Remove user data from local storage on logout
        window.location.reload(); // Refresh the page after logout
    };

    return (
        <div className="user-area">
            {/* Display login button if not logged in */}
            {!userData && (
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <GoogleLogin locale="en" onSuccess={handleLoginSuccess} />
                </GoogleOAuthProvider>
            )}

            {/* Display logout button if logged in */}
            {userData && userData.hd && userData?.hd.includes("c2") && (
                <div>
                    <p>Welcome, {userData.name}!</p>
                    <a href="#" className='logout' onClick={handleLogout}>Logout</a>
                </div>
            )}

            {/* Redirect to a different page if needed */}
        </div>
    );
};

export default Login;
